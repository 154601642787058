import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [quote, setQuote] = useState(""); // change to empty array to get rid of dummy data
  const [quoteList, setQuoteList] = useState([]);

  useEffect(() => {
    const selectQuote = async () => {
      const { data: quoteObject } = await axios.get("https://stoicquotesapi.com/v1/api/quotes/random");
      setQuote(quoteObject.body);
    }
    selectQuote();
  }, []);

  const generateNewQuote = async () => {
    const { data: quoteObject } = await axios.get("https://stoicquotesapi.com/v1/api/quotes/random");
    setQuoteList([quote, ...quoteList]);
    setQuote(quoteObject.body);
  }
  
  return (
    <div>
      <header>
        <h1>Stoic quote (food for thought)</h1> 
        <div className='full_url'>{quote}</div>
      </header>
      <div>
          <button onClick={generateNewQuote}>Generate new quote</button>
          <div>
            <h3>Log of Quotes</h3>
            <UrlList urls={quoteList}/>
          </div>
      </div>
      <footer>
        <h1>Honor thy hunger, eat thy ego.</h1>
      </footer>
    </div>
  );
}

const UrlList = ({ urls }) => {
  if (urls.length > 0) {
    return (
      <ul>
        {urls.map(quote => <li className='rowQuote'>{quote}</li>)}
      </ul>
      ); 
  } else {
    return (
      <ul>
        You have not generated any quotes yet. Click generate quote to start a list!
      </ul>
      ); // Iterate through array of URL items, display key components of each one 
  }  
}

export default App;
